"use strict";

var gqlEndpoint = '/graphql';
var base64Password = '';
function onPasswordEntry() {
    document.getElementById("secretkey").setAttribute("style", "background-color: white;");
}
function onPasswordSubmit() {

    fetch("/config.json", {
        method: 'GET',
        headers: {
            'accept': 'application/json'
        }
    }).then(function (response) {
        if(!response.ok) throw new Error('Network response was not ok');
        console.log('Successful Response');

        return response.json();

    }).then(function (responseAsJSON) {
        if(responseAsJSON) {
            gqlEndpoint = responseAsJSON.gqlEndpoint;
            console.log('Config Loaded');
            processPage();
        }

    }).catch(function(error) {
        document.getElementById("secretkey").setAttribute("style", "background-color: rgba(255,0,0,0.2);");
        console.log('Error fetching endpoint: ', error.message);
    });

    return false;
}


function processPage() {

    //Convert to Base64 and Store for future Requests
    base64Password = window.btoa(document.getElementById("secretkey").value);

    fetch(gqlEndpoint, {
        method: 'POST',
        headers: {
            'x-auth-jwt': base64Password,
            'Content-Type': 'application/json',
            'accept': 'application/json'
        },
        body: JSON.stringify({
            query: `query getAuthorizedEndpoints {
                        AuthorizedEndpoints {
                            endpoint
                            scope
                        }
                    }`
        })
    }).then(function (response) {
        if(!response.ok) throw new Error('Network response was not ok');
        console.log('Successful Response');

        return response.json();

    }).then(function (responseAsJSON) {

        //window.alert("JSON: " + JSON.stringify(responseAsJSON));
        if(responseAsJSON && responseAsJSON.data.AuthorizedEndpoints.length > 0) launchSite();

    }).catch(function(error) {
        document.getElementById("secretkey").setAttribute("style", "background-color: rgba(255,0,0,0.2);");
        console.log('Error fetching endpoint: ', error.message);
    });

    return false;
}

var frameContent = "app_allocation_v2/index.html?allowChange=1&cert=32551&token=";
var contentSize = "left: 332px; height: 520px; width: 820px;";
function launchSite() {
    var tb = document.getElementById("secretkey");

    document.getElementById("cctlogo").setAttribute("style","opacity: 0.0;");

    document.getElementById("entry").setAttribute("style","top: 95px; left: 50px; height: 45px;transform: none;opacity:0.8;");
    document.getElementById("menu1").setAttribute("style","opacity: 1.0;");
    document.getElementById("menu2").setAttribute("style","opacity: 1.0;");
    document.getElementById("menu3").setAttribute("style","opacity: 1.0;");
    document.getElementById("menu4").setAttribute("style","opacity: 1.0;");
    document.getElementById("menu5").setAttribute("style","opacity: 1.0;");
    //document.getElementById("menu6").setAttribute("style","opacity: 1.0;");
    document.getElementById("content").setAttribute("style", contentSize);

    window.setTimeout(function() {
        document.getElementById("content-frame").setAttribute("src", frameContent + base64Password);
        document.getElementById("menu1").setAttribute("style","opacity: 1.0;transition-delay: 0s;");
        document.getElementById("menu2").setAttribute("style","opacity: 1.0;transition-delay: 0s;");
        document.getElementById("menu3").setAttribute("style","opacity: 1.0;transition-delay: 0s;");
        document.getElementById("menu4").setAttribute("style","opacity: 1.0;transition-delay: 0s;");
        document.getElementById("menu5").setAttribute("style","opacity: 1.0;transition-delay: 0s;");
        //document.getElementById("menu6").setAttribute("style","opacity: 1.0;transition-delay: 0s;");
    }, 500);

    window.setTimeout(function() {
        document.getElementById("content-frame").setAttribute("style", "width: 100%; height: 100%; border: none; opacity: 1.0;");
    }, 1500);
}

var selected = 'menu1';
function changeContent(e) {
    document.getElementById(selected).classList.remove("selected");
    document.getElementById(e.target.id).classList.add("selected");
    selected = e.target.id;

    document.getElementById("content").setAttribute("style","left: 332px; height: 50px; width: 50px; opacity:0.0; transition-delay: 0s;");

    frameContent = "placeholder";
    contentSize = "left: 332px; height: 400px; width: 900px; transition-delay: 0s;";

    if(selected == 'menu1') {
        frameContent = "app_allocation_v2/index.html?allowChange=1&cert=32551&token=" + base64Password;
        contentSize = "left: 332px; height: 520px; width: 820px; transition-delay: 0s;";
    }
    if(selected == 'menu2') {
        frameContent = "app_target_v2/index.html?allowChange=1&cert=32551&token=" + base64Password;
        contentSize = "left: 332px; height: 695px; width: 870px; transition-delay: 0s;";
    }
    if(selected == 'menu3') {
        frameContent = "app_yield_v2/index.html?allowChange=1&cert=32551&token=" + base64Password;
        contentSize = "left: 332px; height: 520px; width: 820px; transition-delay: 0s;";
    }
    if (selected == 'menu4') {
        frameContent = "ncua_allocation_v2/index.html?allowChange=1&cu_number=28&token=" + base64Password;
        contentSize = "left: 332px; height: 520px; width: 820px; transition-delay: 0s;";
    }
    if (selected == 'menu5') {
        frameContent = "ncua_target_v2/index.html?allowChange=1&cu_number=28&token=" + base64Password;
        contentSize = "left: 332px; height: 695px; width: 870px; transition-delay: 0s;";
    }
    if (selected == 'menu6') {
        frameContent = "ncua_yield_v2/index.html?allowChange=1&cu_number=28&token=" + base64Password;
        contentSize = "left: 332px; height: 520px; width: 820px; transition-delay: 0s;";
    }


    window.setTimeout(function() {
        document.getElementById("content-frame").setAttribute("src", frameContent);
        document.getElementById("content").setAttribute("style",contentSize + " opacity:1.0;");
    }, 500);
}

window.setTimeout(function() {
    document.getElementById("entry").setAttribute("style","opacity:1.0;");
}, 1000);
